import { Box } from "@mui/material";
import React from "react";
import envolveLogo from "../assets/envolve.jpg";
import instaLogo from "../assets/instagram-white-icon.svg";
import aboutMeImage from "../assets/aboutme.jpeg";
import safeSpaceImage from "../assets/safe-space.jpeg";
import servicesImage1 from "../assets/services1.png";
import servicesImage2 from "../assets/services2.png";

const Main: React.FC = () => {
  const openInstagramLink = () => {
    window.open(
      "https://www.instagram.com/envolve.co?igsh=eWw2d2ZiNXpwY3o5",
      "_blank"
    );
  };

  const openCalenderLink = () => {
    window.open("https://calendar.app.google/2oYt2Fd1ShsQS63e8", "_blank");
  };

  return (
    <div className="App">
      {/* Header */}
      <Box id="header" className="header">
        <Box className="envolveWrapper">
          <img src={envolveLogo} alt="envolve" className="envolveLogo" />
        </Box>
        <Box className="navigationWrapper">
          <Box>
            <Box className="navButton">Home</Box>
          </Box>
          <Box>
            <Box className="navButton">
              <a href="#contact">Contact</a>
            </Box>
          </Box>
          <Box className="instaLogoWrapper">
            <img
              src={instaLogo}
              alt="insta"
              className="instaLogo"
              onClick={() => openInstagramLink()}
            />
          </Box>
          <Box>
            <Box className="primaryButton" onClick={() => openCalenderLink()}>
              GET STARTED
            </Box>
          </Box>
        </Box>
      </Box>

      {/* Home Page */}
      <Box id="home" className="home">
        <Box className="homeText">Get involved in yourself to evolve</Box>
      </Box>

      {/* My Approach */}
      <Box id="myApproach" className="myApproach wrapper">
        <Box className="myApproachHeader textHeading">My approach</Box>
        <Box className="myApproachText">
          <p>
            Experience therapy with a blend of Integrative, Gestalt, Dialectical
            Behavior, and Narrative therapy approach. Working towards greater
            self-understanding and holistic well-being through personalized
            counselling sessions.
          </p>
          <p>Sessions in English, French and Arabic.</p>
          <p>Make the first step and invest in yourself to evolve.</p>
        </Box>
        <Box className="primaryButton" onClick={() => openCalenderLink()}>
          GET STARTED
        </Box>
      </Box>

      {/* Safe Space */}
      <Box id="safeSpace" className="safeSpace wrapper">
        <Box className="safeSpaceImageWrapper">
          <img
            src={safeSpaceImage}
            alt="safeSpace"
            className="safeSpaceImage"
          />
        </Box>
        <Box className="safeSpaceTextWrapper">
          <Box className="safeSpaceHeader textHeading">
            Providing you a safe & confidential space to exchange
          </Box>
          <Box className="safeSpaceText">
            <p>
              During all your appointments, I will make sure that you are
              comfortable enough. With efforts from your side and my help, we
              will work together towards your goals.
            </p>
          </Box>
        </Box>
      </Box>

      {/* About Me */}
      <Box id="aboutMe" className="aboutMe wrapper">
        <Box className="aboutMeHeader textHeading">About me</Box>
        <Box className="aboutMeTextImageWrapper">
          <Box className="aboutMeImage">
            <img src={aboutMeImage} alt="aboutMe" className="aboutMeImage" />
          </Box>
          <Box className="aboutMeTextWrapper">
            <Box className="aboutMeSubHeader textSubHeading">Hadjar Omayon</Box>
            <Box className="aboutMeText">
              <ul>
                <li>Social Psychology graduate</li>
                <li>Multicultural personal & professional experiences</li>
                <li>Personal life coaching training</li>
                <li>4 years of practice</li>
              </ul>
            </Box>
          </Box>
        </Box>
      </Box>

      {/* Our Services */}
      <Box id="services" className="services wrapper">
        <Box className="servicesHeader textHeading">Our services</Box>
        <Box className="servicesSubWrapper">
          <Box className="servicesCardWrapper">
            <Box className="servicesImage">
              <img
                src={servicesImage1}
                alt="services"
                className="servicesImage"
              />
            </Box>
            <Box className="servicesSubHeader textSubHeading2">
              Individual Active Therapy
            </Box>
            <Box className="servicesText">
              <p>
                A safe space for clients to confront challenges, develop
                insight, and cultivate meaningful change. Together, we embark on
                a journey towards greater self-understanding. A unique blend of
                approaches tailored to your individual needs from Integrative
                Therapy, Gestalt Therapy, Dialectical Behavior Therapy, and
                Narrative Therapy.
              </p>
            </Box>
          </Box>
          <Box className="servicesCardWrapper">
            <Box className="servicesImage">
              <img
                src={servicesImage2}
                alt="services"
                className="servicesImage"
              />
            </Box>
            <Box className="servicesSubHeader textSubHeading2">
              Life Coaching
            </Box>
            <Box className="servicesText">
              <p>
                Unlock your full potential with personalized life coaching
                services. Providing guidance to help you overcome obstacles,
                clarify goals, and achieve a better quality of life. Whether
                you're navigating life transitions, seeking personal growth, or
                enhancing relationships, I'm here to support you every step of
                the way.
              </p>
            </Box>
          </Box>
        </Box>
      </Box>

      {/* Qoute */}
      <Box id="qoute" className="qoute wrapper">
        <Box className="qouteHeader">
          “I believe that every aspect of your life can be improved if you are
          willing to work on it”
        </Box>
      </Box>

      {/* Get Started */}
      <Box id="getStarted" className="getStarted wrapper">
        <Box className="getStartedHeader textHeading">Get started today</Box>
        <Box className="primaryButton" onClick={() => openCalenderLink()}>
          BOOK A FREE CONSULTATION
        </Box>
      </Box>

      {/* Contact Us */}
      <Box id="contact" className="contact">
        <h2>Contact Us</h2>
        <p>Hadjar Omayon</p>
        <p>Prague, Czechia</p>
        <p>
          Email:{" "}
          <a className="email" href="mailto:omayon.ha@gmail.com">
            omayon.ha@gmail.com
          </a>
        </p>
        <Box className="instaLogoWrapper">
          <img
            src={instaLogo}
            alt="insta"
            className="instaLogo blackInstaLogo"
            onClick={() => openInstagramLink()}
          />
        </Box>
      </Box>

      <footer className="footer">
        <p>&copy; 2024 Hadjar Omayon</p>
        <p className="signature">SR</p>
      </footer>
    </div>
  );
};

export default Main;
